import React, { useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Tooltip,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AdminCreateQr from "../modals/AdminCreateQr";
import AsmProductSaleModal from "../modals/admin/AsmProductSaleModal";
import AdminCreateVirtualAcct from "../modals/AdminCreateVirtualAcct";
import AdminChargesForApiUsers from "../modals/AdminChargesForApiUsers";
import AdminDeletesUserModal from "../modals/AdminDeletesUserModal";
import AssignmentIcon from "@mui/icons-material/Assignment";
const DataRequestComponent = ({ row, refresh, user }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [ip, setIp] = useState("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const gotoAdminAgrement = (row) => {
    const queryString = new URLSearchParams({
      rowData: row?.id,
      name: row?.name,
      pan: row?.pan,
      role: row?.role,
      state: row?.state,
      establishment: row?.establishment,
      username: row?.username,
    }).toString();

    console.log("Query String:", queryString);

    window.open(`/admin_service?${queryString}&ip=${ip}&type=ret_dd`, "_blank");
  };
  const gotoAdminDistributorAgreement = (row) => {
    const queryString = new URLSearchParams({
      rowData: row?.id,
      name: row?.name,
      pan: row?.pan,
      role: row?.role,
      establishment: row?.establishment,
      username: row?.username,
    }).toString();

    console.log("Query String:", queryString);

    window.open(
      `/admin_distributoragreement?${queryString}&ip=${ip}&type=ret_dd`,
      "_blank"
    );
  };

  // useEffect(() => {
  //   if (location.state) {
  //     setQuery(`username=${location.state.username}`);
  //   }
  // }, [location.state]);
  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls={open ? "data-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="data-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {user && user.role === "Admin" && (
          <>
            <MenuItem>
              <AdminCreateQr row={row} refresh={refresh} />
            </MenuItem>
            <Divider sx={{ backgroundColor: "blue", height: 2 }} />
            <MenuItem>
              <AdminCreateVirtualAcct row={row} refresh={refresh} />
            </MenuItem>
            <Divider sx={{ backgroundColor: "blue", height: 2 }} />
            <MenuItem>
              <AsmProductSaleModal
                role={row.role}
                name={row.name}
                id={row.id}
                amount={
                  <span style={{ color: "black" }}>Performance Report</span>
                }
                usedInUserTable
              />
            </MenuItem>
          </>
        )}
        {row?.role === "Api" && (
          <>
            <Divider sx={{ backgroundColor: "blue", height: 2 }} />
            <MenuItem>
              <AdminChargesForApiUsers row={row} />
            </MenuItem>
          </>
        )}
        {user?.id.toString() === "1" && (
          <>
            <Divider sx={{ backgroundColor: "blue", height: 2 }} />
            <MenuItem>
              <AdminDeletesUserModal row={row} refresh={refresh} />
            </MenuItem>
          </>
        )}
  {row.aggreement === 1 && (row.role === "Ret" || row.role === "Dd") && (
  <>
    <Divider sx={{ backgroundColor: "blue", height: 2 }} />
    <MenuItem onClick={() => gotoAdminAgrement(row)}>
      <Typography variant="body1">Retailer Agreement</Typography>
      {/* <AssignmentIcon /> */}
    </MenuItem>
  </>
)}
{row.aggreement === 1 && (row.role === "Ad" || row.role === "Md") && (
  <>
    <Divider sx={{ backgroundColor: "blue", height: 2 }} />
    <MenuItem onClick={() => gotoAdminDistributorAgreement(row)}>
      <Typography variant="body1">Distributor Agreement</Typography>
    </MenuItem>
  </>
)}

      </Menu>
    </div>
  );
};

export default DataRequestComponent;
