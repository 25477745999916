export const primaryColor = () => {
  if (process.env.REACT_APP_TITLE === "ImpsGuru") {
    return "#1877F2";
  }
};
export const primaryLight = () => {
  if (process.env.REACT_APP_TITLE === "ImpsGuru") {
    return "#1877F2";
  }
};
export const primaryLightest = () => {
  if (process.env.REACT_APP_TITLE === "ImpsGuru") {
    return "#baa7d1";
  }
};
export const secondaryColor = () => {
  if (process.env.REACT_APP_TITLE === "ImpsGuru") {
    return "#1877F2";
  }
};

export const getHoverActive = () => {
  if (process.env.REACT_APP_TITLE === "ImpsGuru") {
    return "#231942";
  }
};
export const getHoverInActive = () => {
  if (process.env.REACT_APP_TITLE === "ImpsGuru") {
    return "#4045A1";
  }
};

export const getTableHeadRowColor = () => {
  if (process.env.REACT_APP_TITLE === "ImpsGuru") {
    return "#18A3F2";
  }
};

export const getEnv = () => {
  if (process.env.REACT_APP_TITLE === "ImpsGuru") {
    return "ImpsGuru";
  }
};
export const blackColor = () => {
  return "#1a1a1a";
};
export const whiteColor = () => {
  return "#f5f5f5";
};
export const gradientColor = () => {
  // return "linear-gradient(135deg, #4A90E2, #8E44AD)";
  // return "linear-gradient(135deg, #E0E0E0, #FFFFFF)";
  // return "linear-gradient(135deg, #A1C4FD, #C2E9FB)";
  // return "linear-gradient(135deg, #F3E5D8, #FFF7F1)";
  // return "linear-gradient(135deg, #D4FC79, #96E6A1)";
  // return "linear-gradient(135deg, #B8D8BA, #F0FDF4)";
  // return "linear-gradient(135deg, #EDE7DC, #FFFFFF)";
  // return "linear-gradient(135deg, #A8E063, #56AB2F)";
  // return "linear-gradient(135deg, #B8D8BA, #F0FDF4)";
  // return "linear-gradient(135deg, #006400, #90EE90)";
  return "linear-gradient(135deg, #87CEFA, #1E90FF)";
  // return "linear-gradient(135deg, #8E44AD, #C39BD3)";
  // return  "linear-gradient(135deg, #6A11CB, #2575FC)";
  // return "linear-gradient(135deg, #5B2C6F, #D2B4DE)";
  // return "linear-gradient(135deg, #FF7E5F, #FEB47B)";
  // return "linear-gradient(135deg, #E53935, #FFCDD2)";
  // return "linear-gradient(135deg, #FF6F61, #FAD961)";
  // return "linear-gradient(135deg, #2C3E50, #BDC3C7)";
  // return "linear-gradient(135deg, #FF9A9E, #FAD0C4)";
  // return "linear-gradient(135deg, #F953C6, #B91D73)";
  // return "linear-gradient(135deg, #F9D423, #FF4E50)";
  // return "linear-gradient(135deg,  #C5E1A5	,#8BC34A  		)";
  // return "linear-gradient(135deg, #D7D2CC, #304352)";
  // return "linear-gradient(135deg, #FF512F,rgb(181, 36, 102), #1FA2FF)";
  // return "linear-gradient(135deg,#26C6DA		, #00BCD4 		)";
  // return "linear-gradient(135deg,#FFD700 	,  #FFD700 )";
};

// user icon bg color change functions . . . .
export const getUserColor = (role) => {
  if (process.env.REACT_APP_TITLE === "ImpsGuru") {
    if (role === "Asm") {
      return "#1C2E46";
    } else if (role === "ZSM") {
      return "#FFC0CB";
    } else if (role === "Ad") {
      return "#16BA75";
    } else if (role === "Md") {
      return "#beb83a";
    } else if (role === "Ret") {
      return "#f48f26";
    } else if (role === "Dd") {
      return "#4F46E5";
    } else if (role === "Api") {
      return "#FF3B30";
    }
  } else if (process.env.REACT_APP_TITLE === "PaisaKart") {
    if (role === "Asm") {
      return "#f48f26";
    } else if (role === "Ad") {
      return "#3f3f3f";
    } else if (role === "Ret") {
      return "#dc5f5f";
    } else if (role === "Dd") {
      return "#0437F2";
    } else if (role === "Api") {
      return "#ff9800";
    }
  } else if (process.env.REACT_APP_TITLE === "MoneyOddr") {
    if (role === "Asm") {
      return "#f48f26";
    } else if (role === "Ad") {
      return "#3f3f3f";
    } else if (role === "Ret") {
      return "#dc5f5f";
    } else if (role === "Dd") {
      return "#00BF78";
    } else if (role === "Api") {
      return "#ff9800";
    }
  }
};

export const randomColors = () => {
  // Array containing colors
  var colors = [
    "rgb(153, 102, 255 , 0.20)",
    "rgb(75, 192, 192 , 0.20)",
    "rgb(255, 204, 86 , 0.20)",
    "rgb(255, 99, 133 , 0.20)",
    "#d3d3d3",
  ];

  // selecting random color
  var random_color = colors[Math.floor(Math.random() * colors.length)];
  return random_color;
};
export const randomTheme = () => {
  // Array containing colors
  var colors = [
    "linear-gradient(135deg, #4A90E2, #8E44AD)",
    "linear-gradient(135deg, #E0E0E0, #FFFFFF)",
    "linear-gradient(135deg, #A1C4FD, #C2E9FB)",
    "linear-gradient(135deg, #F3E5D8, #FFF7F1)",
    "linear-gradient(135deg, #D4FC79, #96E6A1)",
    "linear-gradient(135deg, #B8D8BA, #F0FDF4)",
    "linear-gradient(135deg, #EDE7DC, #FFFFFF)",
    "linear-gradient(135deg, #A8E063, #56AB2F)",
    "linear-gradient(135deg, #B8D8BA, #F0FDF4)",
    "linear-gradient(135deg, #006400, #90EE90)",
    "linear-gradient(135deg, #87CEFA, #1E90FF)",
    "linear-gradient(135deg, #8E44AD, #C39BD3)",
    "linear-gradient(135deg, #6A11CB, #2575FC)",
    "linear-gradient(135deg, #5B2C6F, #D2B4DE)",
    "linear-gradient(135deg, #FF7E5F, #FEB47B)",
    "linear-gradient(135deg, #E53935, #FFCDD2)",
    "linear-gradient(135deg, #FF6F61, #FAD961)",
    "linear-gradient(135deg, #2C3E50, #BDC3C7)",
    "linear-gradient(135deg, #FF9A9E, #FAD0C4)",
    "linear-gradient(135deg, #F953C6, #B91D73)",
    "linear-gradient(135deg, #F9D423, #FF4E50)",
    "linear-gradient(135deg,  #C5E1A5	,#8BC34A  		)",
    "linear-gradient(135deg, #D7D2CC, #304352)",
    "linear-gradient(135deg, #FF512F,rgb(181, 36, 102), #1FA2FF)",
    "linear-gradient(135deg,#26C6DA		, #00BCD4 		)",
    "linear-gradient(135deg,#FFD700 	,  #FFD700 )",
  ];

  // selecting random color
  var random_color = colors[Math.floor(Math.random() * colors.length)];
  return random_color;
};
export const getStatusColor = (status) => {
  const st = status?.toLowerCase();
  if (st === "total") {
    return "#4045A1";
  }
  if (st === "success" || st === "paid") {
    return "#00bf78";
  }
  if (st === "pending" || st === "post") {
    return "#FFCC56";
  }
  if (st === "failed") {
    return "#DC5F5F";
  }
  if (st === "refund") {
    return "#9F86C0";
  } else {
    return "#DC5F5F";
  }
};

export const getFirmAddress = () => {
  if (process.env.REACT_APP_TITLE === "ImpsGuru") {
    return `Mobifast Solutions Plot No. 8, Pocket 6, Sector 22, Rohini, Delhi, 110086`;
  }
};
export const getFirmContact = () => {
  if (process.env.REACT_APP_TITLE === "ImpsGuru") {
    return `01149954822`;
  }
};
export const getFirmEmail = () => {
  if (process.env.REACT_APP_TITLE === "ImpsGuru") {
    return `impsguru@gmail.com`;
  }
};

export const getPriorityBg = (priority) => {
  if (priority === "HIGH") {
    return "#f98f90";
  }
  if (priority === "MEDIUM") {
    return "#fbd288";
  }
  if (priority === "LOW") {
    return "#fbd48d";
  }
};
export const getPriorityColor = (priority) => {
  if (priority === "HIGH") {
    return "#440304";
  }
  if (priority === "MEDIUM") {
    return "#ae3e07";
  }
  if (priority === "LOW") {
    return "#452d02";
  }
};
