import React from "react";
import { Box, Grid, TextField, Button, Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import UploadIcon from "@mui/icons-material/Upload";
import DocumentUploader from "./DocumentUploder";

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  aadharNumber: Yup.string()
    .required("Aadhaar number is required")
    .matches(/^[0-9]{12}$/, "Aadhaar number must be 12 digits"),
  mobileNumber: Yup.string()
    .required("Mobile number is required")
    .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits"),
  panNumber: Yup.string()
    .required("PAN number is required")
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "PAN number must be valid"),
  gstNumber: Yup.string()
    .required("GST number is required")
    .matches(/^[0-9]{15}$/, "GST number must be 15 digits"),
  address: Yup.string().required("Address is required"),
  shopPic: Yup.mixed().required("Shop picture is required"),
  aadharPic: Yup.mixed().required("Aadhaar picture is required"),
  panPic: Yup.mixed().required("PAN picture is required"),
  gstPic: Yup.mixed().required("GST picture is required"),
});

const Documents = () => {
  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("Uploaded file:", file.name);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Upload Documents
      </Typography>
      <DocumentUploader view={"UploadData"}/>
    </Box>
  );
};

export default Documents;
