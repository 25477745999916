import { Box, IconButton, Tooltip, Grid } from "@mui/material";
import React from "react";
// import ApiPaginate from "../component/ApiPaginate";
import ApiEndpoints from "../network/ApiEndPoints";
import CachedIcon from "@mui/icons-material/Cached";
import { useState } from "react";
import { massegetable } from "../component/CustomStyle";
import { ddmmyy, dateToTime1 } from "../utils/DateUtils";
import ApiPaginateSearch from "../component/ApiPaginateSearch";
import FilterCard from "../modals/FilterCard";
import useCommonContext from "../store/CommonContext";
let refresh;
function refreshFunc(setQueryParams) {
  setQueryParams("");
  if (refresh) refresh();
}
const AdminInMassegeView = () => {
  const [apiData, setApiData] = useState([]);
  const [query, setQuery] = useState();

  const [isShowFilterCard, setIsShowFilterCard] = useState(false);
  const {
    setChooseInitialCategoryFilter,
    chooseInitialCategoryFilter,
    refreshUser,
  } = useCommonContext();
  const columns = [
    {
      name: "ID",
      selector: (row) => <div className="blue-highlight-txt">{row.id}</div>,
      width: "80px",
    },
    {
      name: "Date",
      selector: (row) => (
        <div style={{ fontSize: "15px",}}>
          {ddmmyy(row.created_at)} {dateToTime1(row.created_at)}
        </div>
      ),
      width: "140px",
     
    },
    {
      name: "From",
      selector: (row) =>( <div style={{fontSize: "15px",marginRight:"6px"}}>{row.sender}</div>),
      width: "140px",
      
    },
    {
      name: "Message",
      selector: (row) => (
        <div
          style={{
            whiteSpace: "break-spaces",
            fontSize: "15px",
            textAlign: "left",
          }}
        >
          {row.msg}
        </div>
      ),
      wrap: true,
      
    },
  ];

  const searchOptions = [{ field: "Sender", parameter: "sender" }];

  return (
    <Box sx={{ position: "relative" }}>
      <Grid>
        <div>
          <ApiPaginateSearch
            apiEnd={ApiEndpoints.GET_MASSEGE}
            searchOptions={searchOptions}
            showSearch={true}
            actionButtons={
              <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Grid item sx={{ width: "100%"}}>
                <Box>
                      <Tooltip title="refresh">
                        <IconButton
                          aria-label="refresh"
                          sx={{ color: "#0F52BA" }}
                          onClick={() => refreshFunc(setQuery)}
                        >
                          <CachedIcon className="refresh-purple" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                </Grid>
              </Grid>
            }
            setQuery={setQuery}
            query={query}
            columns={columns}
            apiData={apiData}
            tableStyle={massegetable}
            setApiData={setApiData}
            isFilterAllowed={true}
            ExpandedComponent=""
            queryParam={query ? query : ""}
            returnRefetch={(ref) => {
              refresh = ref;
            }}
          />
        </div>
      </Grid>
    </Box>
  );
};

export default AdminInMassegeView;
