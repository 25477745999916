
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { get, postJsonData } from "../network/ApiController";
import ApiEndpoints from "../network/ApiEndPoints";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { apiErrorToast } from "../utils/ToastUtil";
import DeleteBeneficiaryModal from "../modals/DeleteBeneficiaryModal";
import DmrAddRemitterModal from "../modals/DmrAddRemitterModal";
import AddBeneficiaryUpiModal from "../modals/AddBeneficiaryUpiModal";
import AccountVerificationUpi from "../modals/AccountVerificationUpi";
import RetUpiTransferModal from "../modals/RetUpiTransferModal";
import VerifiedIcon from "@mui/icons-material/Verified";
import Loader from "../component/loading-screen/Loader";
import CustomCard from "../component/CustomCard";
import {
  Banner,
  Call1,
  LimitAcc,
  LimitTran,
  Name,
  back,
  noDataFoundGif,
} from "../iconsImports";
import { PATTERNS } from "../utils/ValidationUtil";
import { currencySetter } from "../utils/Currencyutil";
import { randomColors } from "../theme/setThemeColor";
import AuthContext from "../store/AuthContext";
import OutletRegistration from "../component/OutletRegistration";
import { banking } from "../_nav";
import HNavButton from "../component/HNavButton";
import { useNavigate } from "react-router-dom";
import BeneCardUpi from "../component/BeneCardUpi";
import NoDataView from "../component/NoDataView";
import BeneSearchBar from "../component/BeneSearchBar";
import BbpsCardComponent from "../component/BbpsCardComponent";
import CommonCardServices from "./CommonCardServices";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
const UPITransferView = ({
  resetView,
  id,
  dataCategories,
  handleCardClick,
}) => {
  const [infoFetchedMob, setInfoFetchedMob] = useState(false);
  const [request, setRequest] = useState(false);
  const [remitterStatus, setRemitterStatus] = useState();
  const [mobile, setMobile] = useState("");
  const [bene, setBene] = useState([]);
  const [addNewRem, setAddNewRem] = useState(false);
  const [verifyRem, setVerifyRem] = useState(false);
  const [isMobv, setIsMobv] = useState(true);
  const [search, setSearch] = useState("");
  const scrollContainerRef = useRef(null);
  const authCtx = useContext(AuthContext);
  const user = authCtx.user;
  const navigate = useNavigate();
  const handleBack = () => {
    resetView(false);
  };
  const returnMoneyNew = () => {
    setInfoFetchedMob(false);
  };
  const getRemitterStatus = (number) => {
    setMobile(number);
    postJsonData(
      ApiEndpoints.GET_REMITTER_STATUS_UPI,
      { rem_number: number },
      setRequest,
      (res) => {
        if (res && res.data) {
          if (res.data.message === "Verify Remitter") {
            setAddNewRem(true);
            setVerifyRem(true);
          } else {
            const data = res.data;
            setMobile(number);
            setRemitterStatus(data.remitter);
            setBene(data.data);
            setInfoFetchedMob(true);
          }
        } else {
          setRemitterStatus();
        }
      },
      (error) => {
        const errorData = error.response;
        const err_status = errorData.status;
        const err_message = errorData.data.message;

        if (err_status === 404 && err_message === "Remitter Not Found") {
          setVerifyRem(true);
          setAddNewRem(true);
        } else {
          apiErrorToast(error);
        }
      }
    );
  };

  const ekycCall = () => {
    get(
      ApiEndpoints.EKYC_INITIATE,
      `rem_mobile=${mobile && mobile}`,
      setRequest,
      (res) => {
        const data = res.data;
        window.open(data.url);
      },
      (error) => {
        apiErrorToast(error);
      }
    );
  };

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 200; // Adjust this value as needed
      scrollContainerRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };
  return (
    <>
      {user && !user.instId && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <OutletRegistration autoOpen />
        </Box>
      )}

      {user && user.instId && (
        <>
          {/* {user?.layout && user?.layout === 2 && (
            <Box
              className="card-css"
              sx={{
                width: "100%",
                my: 2,
                p: 2,
                py: 1,
              }}
            >
              <Typography className="services-heading">
                Banking Services
              </Typography>
              <Grid container>
                {user?.st === 0 ||
                user.dmt4 === 0 ||
                user?.aeps === 0 ||
                user?.nepal_transfer === 0 ||
                user?.upi_transfer === 0
                  ? banking
                      .filter((item) => {
                        if (user?.st === 0 && item.title === "Super Transfer") {
                          return undefined;
                        }
                        if (
                          user?.dmt4 === 0 &&
                          item.title === "Express Transfer"
                        ) {
                          return undefined;
                        }
                        if (user?.aeps === 0 && item.title === "AEPS") {
                          return undefined;
                        }
                        if (
                          user?.nepal_transfer === 0 &&
                          item.title === "Nepal Transfer"
                        ) {
                          return undefined;
                        }
                        if (
                          user?.upi_transfer === 0 &&
                          item.title === "UPI Transfer"
                        ) {
                          return undefined;
                        } else {
                          return item;
                        }
                      })
                      .map((mitem, index) => (
                        <Grid
                          item
                          md={2}
                          index={index}
                          onClick={() => navigate(mitem.to)}
                          className="horizontal-sidenav"
                        >
                          <HNavButton item={mitem} />
                        </Grid>
                      ))
                  : banking.map((item, index) => (
                      <Grid
                        item
                        md={2}
                        index={index}
                        onClick={() => navigate(item.to)}
                        className="horizontal-sidenav"
                      >
                        <HNavButton item={item} />
                      </Grid>
                    ))}
              </Grid>
            </Box>
          )} */}
<Grid sx={{
 ml:1.4
}}>
          {dataCategories?(dataCategories
            .filter((category) => category.title === "Banking")
            .map((category, index) => (
              <Box
                key={index}
                sx={{
                  width: "100%",
                  margin: "0 auto",
                  marginBottom: 1,
                  border: "1px solid lightgray",
                  padding: { lg: 1.1, md: 1.2, xs: 2, sm: 3 },
                  borderRadius: 3,

                  paddingRight: 1,
                  flexWrap: "wrap",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                  position: "relative",
                }}
              >
                <Typography
                  variant="h6"
                  align="left"
                  sx={{
                    ml: 0.5,
                    mt:"-8px",
                    fontSize: { xs: "1rem", sm: "1.25rem" },
                  }}
                >
                  {category.title}
                </Typography>

                <Box
                  sx={{
                    // overflowX: "scroll",
                    height: "100px",
                    display: "flex",
                    flexDirection: "row",
                    mt:-2,
                    alignItems: "center",
                    width: {
                      lg: "67vw",
                      md: "67vw",
                      sm: "82vw",
                      xs: "82vw",
                    },
                    position: "relative",
                  }}
                >
                  <IconButton
                    onClick={() => handleScroll("left")}
                    sx={{
                      position: "absolute",
                      left: 0,
                      mt:1,
                      zIndex: 1,
                      color: "#000",
                    }}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                  <Box
                    ref={scrollContainerRef}
                    sx={{
                      display: "flex",
                      width: "100%",
                      overflowX: "auto",
                      scrollBehavior: "smooth",
                      padding: 1,
                      "&::-webkit-scrollbar": {
                        display: "none", // Hide the scrollbar
                      },
                    }}
                  >
                    {category.data.map((item) => (
                      <Box
                        sx={{
                          px: 1,
                        }}
                      >
                        <CommonCardServices
                          title={item.name}
                          img={item.img}
                          isSelected={id === item.id}
                          onClick={() => handleCardClick(item)}
                          width="200px"
                        />
                      </Box>
                    ))}
                  </Box>
                  <IconButton
                    onClick={() => handleScroll("right")}
                    sx={{
                      position: "absolute",
                      right: "-17px",
                      mt: 1.5,
                      zIndex: 1,
                      // ml: 2,
                      color: "#000",
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Box>
              </Box>
            ))):[]}

          <Box
            sx={{
            
              px: 3,
              mb: 3,
            }}
            className="position-relative card-css"
          >
            <Loader loading={request} circleBlue />
            <Grid
              container
              spacing={1}
              sx={{ display: "flex", justifyContent: "center", mt: 0.1 }}
            >
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                sx={{
                  mb: { md: 2, sm: 4, xs: 4, lg: 4 },
                  mr: { md: 0, sm: 1.3, xs: 1.3 },
                  marginLeft: 0,
                }}
              >
                {!infoFetchedMob && !infoFetchedMob && (
             <Grid container alignItems="center">
             <Grid
               item
               xs={1} // Occupy minimal space for the button
               sx={{
                 display: "flex",
                 justifyContent: "flex-start", // Align arrow to the left
                 alignItems: "center",
               }}
             >
             { dataCategories&&
               <Button
                 size="small"
                 onClick={handleBack}
                 sx={{
                   display: "flex",
                   justifyContent: "center",
                   alignItems: "center",
                   textTransform: "none",
                   p: 0,
                 }}
               >
                 <ArrowBackIcon sx={{ color: "#000", fontSize: "24px" }} />
               </Button>
}
             </Grid>
             <Grid
               item
               xs={10} // Occupy most space for centering Typography
               sx={{
                 display: "flex",
                 justifyContent: "center", // Center the text
                 alignItems: "center",
               }}
             >
               <Typography
                 sx={{
                   fontSize: "24px",
                   fontWeight: "bold",
                   textAlign: "center",
                   color: "#000",
                 }}
               >
                 UPI Transfer
               </Typography>
             </Grid>
           </Grid>
           
                  // <Grid
                  //   item
                  //   xs={12}
                  //   sm="auto"
                  //   sx={{
                  //     display: "flex",
                  //     alignItems: "center",
                  //     justifyContent: "space-between",
                  //     mr: 2,
                  //     mt: 2,
                  //   }}
                  // >
                  //   <Button
                  //     size="small"
                  //     id="verify-btn"
                  //     className="button-props"
                  //     onClick={handleBack}
                  //   >
                  //     <span style={{ marginRight: "5px" }}>Home</span>
                  //     <img
                  //       src={back}
                  //       alt="back"
                  //       style={{ width: "18px", height: "20px" }}
                  //     />
                  //   </Button>
                  // </Grid>
                )}
                {/* {!mobile ? (
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    UPI Transfer
                  </Typography>
                ) : null} */}

                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { mt: 1 },
                    objectFit: "contain",
                    overflowY: "scroll",
                  }}
                >
                  <Grid container sx={{  }}>
                    <Grid item md={12} xs={12} lg={12}>
                      {!infoFetchedMob && !infoFetchedMob && (
                        <FormControl sx={{ width: "100%" }}>
                          <TextField
                            autoComplete="off"
                            sx={{ width: "100%" }}
                            label="Mobile Number"
                            id="mobile"
                            name="mobile"
                            type="number"
                            size="small"
                            value={mobile}
                            onChange={(e) => {
                              setInfoFetchedMob(false);
                              setBene("");
                              setRemitterStatus("");
                              setIsMobv(PATTERNS.MOBILE.test(e.target.value));
                              if (e.target.value === "") setIsMobv(true);
                              setMobile(e.target.value);
                              if (PATTERNS.MOBILE.test(e.target.value)) {
                                getRemitterStatus(e.target.value);
                              }
                            }}
                            error={!isMobv}
                            helperText={
                              !isMobv ? "Enter valid Mobile Number" : ""
                            }
                            onKeyDown={(e) => {
                              if (
                                (e.which >= 65 && e.which <= 90) ||
                                e.key === "+"
                              ) {
                                e.preventDefault();
                              }
                              if (e.target.value.length === 10) {
                                if (e.key.toLowerCase() !== "backspace") {
                                  e.preventDefault();
                                }
                              }
                            }}
                            inputProps={{
                              form: {
                                autocomplete: "off",
                              },
                              maxLength: "10",
                              
                              inputMode: "numeric", 
                            }}
                            disabled={request && request && true}
                          />
                        </FormControl>
                      )}
                    </Grid>
                    {infoFetchedMob && infoFetchedMob && (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm="auto"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          
                            
                          }}
                        >
                          <Button
                            size="small"
                            id="verify-btn"
                            onClick={handleBack}
                          >
                            {/* <span style={{ marginRight: "5px" }}>Home</span>
                            <img
                              src={back}
                              alt="back"
                              style={{ width: "18px", height: "20px" }}
                            /> */}
                            {dataCategories&&
                            <ArrowBackIcon
                              sx={{ color: "#000", fontSize: "24px" }}
                            />
}
                          </Button>
                        </Grid>
                        <h1
                          style={{
                            fontSize: "24px",
                            fontWeight: "600",
                            color: "#1877f2",
                            textAlign: "center",
                            margin: "0 auto",
                
                          }}
                        >
                          Upi Transfer
                        </h1>
                        <Grid
                          className="remitter-card"
                          container
                          sx={{ display: "flex",alignItems:"center"}}
                        >
                          <Grid
                            item
                            lg={4.5}
                            md={4.5}
                            sm={4.5}
                            xs={4.5}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 2, // Adds spacing between items in this grid
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <Box
                                component="img"
                                src={Name}
                                alt="Name"
                                sx={{
                                  maxWidth: "25px",
                                  maxHeight: "50px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "600",
                                }}
                              >
                                {remitterStatus && remitterStatus.name}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <Box
                                component="img"
                                src={Call1}
                                alt="Call"
                                sx={{
                                  maxWidth: "25px",
                                  maxHeight: "50px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "600",
                                }}
                              >
                                {remitterStatus?.number}
                              </Typography>
                              <BorderColorIcon
                                sx={{
                                  color: "black",
                                  cursor: "pointer",
                                  fontSize: "15px",
                                }}
                                onClick={returnMoneyNew}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            lg={3}
                            md={3}
                            sm={3}
                            xs={3}
                            sx={{
                              display: "flex",
                              justifyContent: "center", // Centers content horizontally
                              alignItems: "center", // Centers content vertically
                            }}
                          >
                            <AddBeneficiaryUpiModal
                              rem_mobile={mobile}
                              apiEnd={ApiEndpoints.ADD_BENE_UPI}
                              getRemitterStatus={getRemitterStatus}
                            />
                          </Grid>
                          <Grid
                            item
                            lg={4.5}
                            md={4.5}
                            sm={4.5}
                            xs={4.5}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 2, // Adds consistent spacing between rows
                              alignItems: "flex-end",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "flex-start",
                                gap: 1,
                                mr: 5.7,
                              }}
                            >
                              <Box
                                component="img"
                                src={LimitAcc}
                                alt="Limit"
                                sx={{
                                  width: "25px",
                                  height: "25px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                Limit Available:
                              </Typography>
                              <Typography sx={{ fontWeight: "bold" }}>
                                {remitterStatus?.rem_limit}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "flex-start",
                                gap: 1,
                              }}
                            >
                              <Box
                                component="img"
                                src={LimitTran}
                                alt="Limit"
                                sx={{
                                  width: "25px",
                                  height: "25px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                Limit Per Transaction:
                              </Typography>
                              <Typography sx={{ fontWeight: "bold" }}>
                                {remitterStatus?.rem_limit}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </Grid>
              {infoFetchedMob && infoFetchedMob && (
                <Grid
                  lg={12}
                  sm={12}
                  xs={12}
                  sx={{ mb: { md: 2, sm: 2, xs: 2 } }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                      mx: {lg:3, md: 2, sm: 1, xs: 0 },
                      mr: { xs: 1.3, md: 2 ,lg:1},
                      mt: -3.8,
                      ml:2,
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                        maxWidth: {
                          lg: "100%",
                          md: "200px",
                          sm: "150px",
                          xs: "100%",
                        }, // Adjust max-width based on screen size
                      }}
                    >
                      <BeneSearchBar setSearch={setSearch} remMargin={true} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                        mx: { md: 2, sm: 1, xs: 0 },
                        mr: { xs: 1.3, md: 2 },
                      }}
                    >
                      <Typography sx={{ fontSize: "18px", mb: 1 }}>
                        Beneficiary List ({bene.length})
                      </Typography>
                    </Box>
                  </Box>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      pr: { xs: 1.3, md: 2 },
                      pt: 2,
                      mb: { md: 2, sm: 4, xs: 4 },
                      marginLeft: 1,
                      maxHeight: '500px',  // Set a max height if needed (adjust as required)
                      overflowY: 'auto',  
                    }}
                  >
                    {bene.length <= 0 ? (
                      <Grid
                        item
                        xs={12}
                        className="d-flex align-items-start justify-content-center"
                      >
                        <Typography sx={{ mt: 2 }}>
                          No beneficiary found.
                          <Typography sx={{ fontWeight: "bold" }}>
                            Enter Remitter's Mobile Number to view Beneficiary
                            List
                          </Typography>
                          <NoDataView />
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid container spacing={0.5}>
                        {bene.map((ben, index) => {
                          return (
                            <Grid
                              item
                              md={12}
                              lg={12}
                              sm={12}
                              xs={12}
                              key={index}
                            >
                              {/* <CustomCard
                          lg={12}
                            width="100%"
                            icon={
                              <Box
                                sx={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                  display: "flex",
                                  borderRadius: "4px",
                                  height: "64px",
                                  background: randomColors(),
                                  width: "64px",
                                }}
                              >
                                <Typography sx={{ fontSize: "40px" }}>
                                  {ben.bene_name.charAt(0).toUpperCase()}
                                </Typography>
                              </Box>
                            }
                           
                            title={ben.bene_acc}
                            
                            description={
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <RetUpiTransferModal ben={ben} rem_number={mobile} />
                              </div>
                            }
                            descriptionSup={
                              <DeleteBeneficiaryModal
                                bene={ben}
                                mob={mobile}
                                getRemitterStatus={getRemitterStatus}
                                apiEnd={ApiEndpoints.REMOVE_BENE_UPI}
                                view="expressTransfer"
                              />

                            }
                            iconSup={
                              <div style={{  }}>
                                {ben.last_success_date ? (
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      top: "-74px",
                                      right: "-68px",
                                      color: "#00bf78",
                                    }}
                                  >
                                    <Tooltip title="Already Verified">
                                      <VerifiedIcon sx={{ fontSize: "17px" }} />
                                    </Tooltip>
                                  </Box>
                                ) : (
                                  <AccountVerificationUpi rem_number={mobile} ben={ben} />
                                )}
                              </div>
                            }
                          /> */}
                              {/* <Grid item md={6} lg={12} sm={12} xs={12}> */}
                              <BeneCardUpi ben={ben} mobile={mobile} />
                            </Grid>
                            // </Grid>
                          );
                        })}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
              {addNewRem && (
                <DmrAddRemitterModal
                  rem_mobile={mobile}
                  getRemitterStatus={getRemitterStatus}
                  // apiEnd={ApiEndpoints.ADD_REM_UPI}
                  apiEnd={ApiEndpoints.NEW_ADD_REM}
                  view="upiTransfer"
                  setAddNewRem={setAddNewRem}
                  verifyRem={verifyRem}
                  setVerifyRem={setVerifyRem}
                />
              )}
            </Grid>
          </Box>
          </Grid>
        </>
      )}
    </>
  );
};

export default UPITransferView;
