import FormatBoldIcon from "@mui/icons-material/FormatBold";
import DashboardIcon from "./assets/sidenav/dashboard.svg";
import cmsIcon from "./assets/sidenav/cms.svg";
import DashboardIconWhite from "./assets/sidenav/white/dashboard.svg";
import AccountIcon from "./assets/sidenav/accounts.svg";
import InstantIcon from "./assets/sidenav/instant.svg";
import BankIcon from "./assets/sidenav/bank.svg";
import ComplaintsIcon from "./assets/sidenav/complaints.svg";
import MessagesIcon from "./assets/sidenav/messages.svg";
import NotificationIcon from "./assets/sidenav/notifications.svg";
import OperatorsIcon from "./assets/sidenav/operators.svg";
import PlansIcon from "./assets/sidenav/plans.svg";
import PrabhuIcon from "./assets/sidenav/prabhu_transfer.svg";
import RoutesIcon from "./assets/sidenav/routes.svg";
import TransactionIcon from "./assets/sidenav/transactions.svg";
import UserIcon from "./assets/sidenav/user.svg";
import user1 from "./assets/sidenav/UserR.svg";
import FlightIcon from "./assets/sidenav/flight_booking.svg";
import AepsIcon from "./assets/sidenav/aeps.svg";
import BbpsIcon from "./assets/sidenav/bbps.svg";
import CreditRequestIcon from "./assets/sidenav/credit_request.svg";
import ExpressIcon from "./assets/sidenav/express_transfer.svg";
import MoneyTransferIcon from "./assets/sidenav/money_transfer.svg";
import NepalIcon from "./assets/sidenav/nepal1.png";
import RechargeIcon from "./assets/sidenav/recharge_bill.svg";
import ReportsIcon from "./assets/sidenav/reports.svg";
import Arrow from "./assets/sidenav/arrows.png";
import upi from "./assets/sidenav/upi-transfer.svg";
import nepal2 from "./assets/sidenav/nepal-flag-icon.svg";
import UpiIcon from "./assets/sidenav/upi_transfer.svg";
import employeesIcon from "./assets/sidenav/employee_icon.svg";
import AccountIconWhite from "./assets/sidenav/white/accounts.svg";
import BankIconWhite from "./assets/sidenav/white/bank.svg";
import ComplaintsIconWhite from "./assets/sidenav/white/complaints.svg";
import MessagesIconWhite from "./assets/sidenav/white/messages.svg";
import NotificationIconWhite from "./assets/sidenav/white/notifications.svg";
import OperatorsIconWhite from "./assets/sidenav/white/operators.svg";
import PlansIconWhite from "./assets/sidenav/white/plans.svg";
import PrabhuIconWhite from "./assets/sidenav/white/prabhu_transfer.svg";
import RoutesIconWhite from "./assets/sidenav/white/routes.svg";
import TransactionIconWhite from "./assets/sidenav/white/transactions.svg";
import UserIconWhite from "./assets/sidenav/white/user.svg";
import InstantIconWhite from "./assets/sidenav/white/instant.svg";
import cmsIconWhite from "./assets/sidenav/white/cms.svg";
import employeesIconWhite from "./assets/sidenav/white/employee_icon_white.svg";
import trainIconWhite from "./assets/sidenav/white/train.svg";
import dashboards from "./assets/sidenav/DashboardR.svg";
import transfer1 from "./assets/sidenav/transfer1.svg";
import creditrq1 from "./assets/sidenav/creditR.svg";
import account1 from "./assets/sidenav/account1.svg";
import transaction1 from "./assets/sidenav/white/transaction1.svg";
import bank1 from "./assets/sidenav/bankR.svg";
import message1 from "./assets/sidenav/MsgR.svg";
import notification1 from "./assets/sidenav/notification1.svg";
import route1 from "./assets/sidenav/route1.svg";
import employee1 from "./assets/sidenav/employee1.svg";
import operator1 from "./assets/sidenav/ServiceR.svg";
import plan1 from "./assets/sidenav/plan1.svg";
import virtualaccount1 from "./assets/sidenav/virtualaccount1.svg";
import complaint1 from "./assets/sidenav/complainR.svg";
import risk1 from "./assets/sidenav/risk1.svg";
import mypurchase from "./assets/sidenav/mypurchase.svg";
import train from "./assets/sidenav/train.svg";
import Upi from "./assets/sidenav/Upi.svg";
import creditmoney from "./assets/sidenav/creditmoney.svg";
import Reports1 from "./assets/sidenav/Reports1.svg";
import Recharge from "./assets/sidenav/Recharge.svg";
import mservice from "./assets/sidenav/mservice.svg";
import Travel from "./assets/sidenav/Travel.svg";
import AEPS1 from "./assets/sidenav/AEPS1.svg";
import complaint from "./assets/sidenav/complaint.svg";
import Cms2 from "./assets/sidenav/Cms2.svg";
import graph from "./assets/sidenav/txnR.svg";
import Book from "./assets/sidenav/book.svg";
import bharatbig from "./assets/sidenav/bharatbig.svg";
import loginhistory from "./assets/sidenav/loginhistory.svg";
import ledgerIcon from "./assets/ledger.svg";
import mysale from "./assets/sidenav/white/mysale.svg";
// import transactionsvg from "./assets/sidenav/transaction.svg"
import transactionsvg from "./assets/sidenav/transactionsvg.svg"
import bankingsvg from "./assets/sidenav/bankingsvg.svg"
import messagesvg from"./assets/sidenav/messagesvg.svg"
import servicesvg from "./assets/sidenav/servicesvg.svg"
import dashbaordsvg from "./assets/sidenav/dashboardsvg.svg"
import complaintsvg from "./assets/sidenav/complaintsvg.svg"
import virtualaccountsvg from "./assets/sidenav/virtualaccountsvg.svg"
import schemesvg from "./assets/sidenav/schemesvg.svg"
import employeesvg from "./assets/sidenav/employeesvg.svg"
import invoicesvg from "./assets/sidenav/envoicesvg.svg"
import usersvg from "./assets/sidenav/usersvg.svg"
import creditsvg from "./assets/sidenav/creditcardsvg.svg"
import risksvg from "./assets/sidenav/risksvg.svg"
import purchasesvg from "./assets/sidenav/purchasesvg.svg"
import {
  account_ledgre,
  aepsIcon,
  bbIcon,
  cardIcon,
  creditReq,
  dashboard,
  dthIcon,
  elecIcon,
  etIcon,
  gasIcon,
  khataBook,
  mtIcon,
  myComplaints,
  myPurchase,
  ntIcon,
  stIcon,
  transactions,
  utIcon,
  Electricity,
  FASTag,
  Cylinder,
  Postpaid,
  Prepaid,
  Gas,
  BBPS,
  licIcon,
  waterIcon,
  landIcon,
  flightBooking,
  hotelBooking,
  busBooking,
  CMSIcon,
  irctcTravelImage,
} from "./iconsImports";
import { complaintsBadgeIcon, creditReqBadgeIcon } from "./iconsImports";
export const nav = [
  {
    title: "Dashboard",
    icon: dashboards,
    icon2: DashboardIconWhite,
    to: "/dashboard",
  },
  {
    title: "Recharge",
    icon: RechargeIcon,
    icon2: RechargeIcon,
    to: "/dashboard/transaction",
  },
  {
    title: "Money Transfer",
    icon: MoneyTransferIcon,
    icon2: MoneyTransferIcon,
    to: "/dashboard/transaction",
  },
  {
    title: "Express Transfer",
    icon: ExpressIcon,
    icon2: ExpressIcon,
    to: "/dashboard/transaction",
  },
  {
    title: "UPI Transfer",
    icon: UpiIcon,
    icon2: UpiIcon,
    to: "/dashboard/transaction",
  },
  {
    title: "AEPS",
    icon: AepsIcon,
    icon2: AepsIcon,
    to: "/dashboard/transaction",
  },
  {
    title: "Transaction",
    icon: InstantIcon,
    icon2: InstantIconWhite,
    to: "/dashboard/transaction",
  },
  {
    title: "Fund Request",
    icon: creditReqBadgeIcon,
    icon2: CreditRequestIcon,
    to: "/dashboard/transaction",
  },
  {
    title: "Contact Us",
    icon: InstantIcon,
    icon2: InstantIconWhite,
    to: "/dashboard/transaction",
  },
];
export const Admin_nav = [
  {
    title: "Dashboard",
    icon: dashbaordsvg,
    icon2: DashboardIconWhite,
    to: "/admin/dashboard",
  },
  {
    title: "Users",
    icon: usersvg,
    icon2: UserIconWhite,
    to: "/admin/users",
  },
  {
    title: "Transactions",
      icon: transactionsvg,
    icon2: ReportsIcon,
    to: "/admin/transactions",
  },
  // {
  //   title: "Prabhu Transfer",
  //   icon: transfer1,
  //   icon2: PrabhuIconWhite,
  //   to: "/admin/prabhu",
  // },
  {
    title: "Fund Requests",
    icon: creditReqBadgeIcon,
    icon2: CreditRequestIcon,
    to: "/admin/cred-req",
  },
  // {
  //   title: "Accounts",
  //   icon: account1 ,
  //   icon2: AccountIconWhite,
  //   to: "/admin/accounts",
  // },
  // {
  //   title: "Banks",
  //   icon: bank1,
  //   icon2: BankIconWhite,
  //   to: "/admin/banks",
  // },
  {
    title: "Banking",
    icon: bankingsvg,
    icon2: BankIconWhite,
    to: "/admin/banking",
  },

  {
    title: "Messages",
    icon: messagesvg,
    icon2: MessagesIconWhite,
    to: "/admin/messages",
  },
  // {
  //   title: "Notifications",
  //   icon: notification1,
  //   icon2: NotificationIconWhite,
  //   to: "/admin/notification",
  // },
  // {
  //   title: "Operators",
  //   icon: operator1,
  //   icon2: OperatorsIconWhite,
  //   to: "/admin/operators",
  // },
  {
    title: "Services",
    icon: servicesvg,
    icon2: OperatorsIconWhite,
    to: "/admin/Services",
  },
  // {
  //   title: "Account Ledger",
  //   icon: ledgerIcon,
  //   icon2: ledgerIcon,
  //   to: "/admin/account-ledger",
  // },
  // {
  //   title: "Routes",
  //   icon: route1,
  //   icon2: RoutesIconWhite,
  //   to: "/admin/routes",
  // },
  // {
  //   title: "Plans",
  //   icon: plan1,
  //   icon2: PlansIconWhite,
  //   to: "/admin/plans",
  // },
  {
    title: "Complaint",
    icon: complaintsvg,
    icon2: ComplaintsIconWhite,
    to: "/admin/complaints",
  },
  {
    title: "Risk",
    icon: risksvg,
    icon2: AccountIconWhite,
    to: "/admin/risk",
  },
  {
    title: "Virtual Accounts",
    icon: virtualaccountsvg,
    icon2: AccountIconWhite,
    to: "/admin/virtual-accounts",
  },
  {
    title: "Schemes",
    icon: schemesvg,
    icon2: TransactionIconWhite,
    to: "/admin/scheme",
  },
  {
    title: "Employees",
    icon: employeesvg,
    icon2: employeesIconWhite,
    to: "/admin/employees",
  },
  {
    title: "Invoices",
    icon: invoicesvg,
    icon2: employeesIconWhite,
    to: "/admin/invoice",
  },
  {
    title: "Login History",
    icon: loginhistory,
    // icon2: TransactionIconWhite,
    to: "/admin/loginHistory",
  },
];
export const Asm_nav = [
  {
    title: "Dashboard",
    icon: DashboardIcon,
    icon2: DashboardIconWhite,
    to: "/asm/dashboard",
  },
  {
    title: "Users",
    icon: user1,
    icon2: UserIconWhite,
    to: "/asm/users",
  },
  {
    title: "Transactions",
    icon: graph,
    icon2: ReportsIcon,
    to: "/asm/transactions",
  },
  {
    title: "Fund Requests",
    icon: creditReqBadgeIcon,
    icon2: CreditRequestIcon,
    to: "/asm/cred-req",
  },
  // {
  //   title: "Invoices",
  //   icon: employee1,
  //   icon2: employeesIconWhite,
  //   to: "/asm/invoice",
  // },
  {
    title: "Login History",
    icon: loginhistory,
    // icon2: TransactionIconWhite,
    to: "/asm/loginHistory",
  },
];
export const Zsm_nav = [
  {
    title: "Dashboard",
    icon: DashboardIcon,
    icon2: DashboardIconWhite,
    to: "/zsm/dashboard",
  },
  {
    title: "Users",
    icon: UserIcon,
    icon2: UserIconWhite,
    to: "/zsm/users",
  },
  {
    title: "Transactions",
    icon: graph,
    icon2: ReportsIcon,
    to: "/zsm/transactions",
  },
  {
    title: "Fund Requests",
    icon: creditReqBadgeIcon,
    icon2: CreditRequestIcon,
    to: "/zsm/cred-req",
  },
  // {
  //   title: "Invoices",
  //   icon: employee1,
  //   icon2: employeesIconWhite,
  //   to: "/zsm/invoice",
  // },
  {
    title: "Login History",
    icon: loginhistory,
    // icon2: TransactionIconWhite,
    to: "/zsm/loginHistory",
  },
  
];
export const Api_nav = [
  {
    title: "Dashboard",
    icon: dashbaordsvg,
    icon2: DashboardIconWhite,
    to: "/api-user/dashboard",
  },
  {
    title: "Transactions",
    icon: transactionsvg,
    icon2: ReportsIcon,
    to: "/api-user/transactions",
  },
  {
    title: "Fund Requests",
    icon: creditReqBadgeIcon,
    icon2: CreditRequestIcon,
    to: "/api-user/cred-req",
  },
  {
    title: "Invoices",
    icon: employee1,
    icon2: employeesIconWhite,
    to: "/api-user/invoice",
  },
  // {
  //   title: "Documents",
  //   icon: employee1,
  //   icon2: employeesIconWhite,
  //   to: "/api-user/documents",
  // },
  {
    title: "Invoices",
    icon: invoicesvg,
    icon2: employeesIconWhite,
    to: "/api-user/invoice",
  },
  {
    title: "Login History",
    icon: loginhistory,
    // icon2: TransactionIconWhite,
    to: "/api-user/loginHistory",
  },
];

export const Ad_nav = [
  {
    title: "Dashboard",
    icon: dashboards,
    icon2: DashboardIconWhite,
    to: "/ad/dashboard",
  },
  {
    title: "Users",
    icon: usersvg,
    icon2: UserIconWhite,
    to: "/ad/users",
  },
  {
    title: "Account Ledger",
    icon: ledgerIcon,
    icon2: ledgerIcon,
    to: "/ad/account-ledger",
  },
  {
    title: "Fund Requests",
    icon: creditReqBadgeIcon,
    icon2: CreditRequestIcon,
    to: "/ad/cred-req",
  },
  {
    title: "Transactions",
      icon: graph,
    icon2: ReportsIcon,
    to: "/ad/transactions",
  },
  {
    title: "Invoices",
    icon: employee1,
    icon2: employeesIconWhite,
    to: "/ad/invoice",
  },
  {
    title: "Login History",
    icon: loginhistory,
    // icon2: TransactionIconWhite,
    to: "/ad/loginHistory",
  },
  // {
  //   title: "My Sale",
  //   icon: TransactionIcon,
  //   icon2: TransactionIconWhite,
  //   to: "/ad/sale",
  // },
  // {
  //   title: "Credit Report",
  //   icon: TransactionIcon,
  //   icon2: TransactionIconWhite,
  //   to: "/ad/purchase",
  // },
  // {
  //   title: "My Ledger",
  //   icon: TransactionIcon,
  //   icon2: TransactionIconWhite,
  //   to: "/ad/ledger",
  // },
  // {
  //   title: "Khata Book",
  //   icon: AccountIcon,
  //   icon2: AccountIcon,
  //   to: "/ad/khata-book",
  // },
];

export const Md_nav = [
  {
    title: "Dashboard",
    icon: DashboardIcon,
    icon2: DashboardIconWhite,
    to: "/md/dashboard",
  },
  {
    title: "Users",
    icon: usersvg,
    icon2: UserIconWhite,
    to: "/md/users",
  },
  {
    title: "Fund Requests",
    icon: creditReqBadgeIcon,
    icon2: CreditRequestIcon,
    to: "/md/cred-req",
  },
  {
    title: "Transactions",
    icon: graph,
    icon2: ReportsIcon,
    to: "/md/transactions",
  },
  {
    title: "Account Ledger",
    icon: ledgerIcon,
    icon2: ledgerIcon,
    to: "/md/account-ledger",
  },
  // {
  //   title: "Contact Us",
  //   icon: <SupportAgentIcon />,
  //   to: "/ad/cred-req",
  // },
  {
    title: "Invoices",
    icon: employee1,
    icon2: employeesIconWhite,
    to: "/md/invoice",
  },
  {
    title: "Login History",
    icon: loginhistory,
    // icon2: TransactionIconWhite,
    to: "/md/loginHistory",
  },
];
// this is the nav for the old layout
export const customer_nav = [
  {
    title: "Dashboard",
    icon: dashbaordsvg,
    icon2: DashboardIconWhite,
    to: "/customer/dashboard",
  },
  {
    title: "Services",
    icon: mservice,
    icon2: RechargeIcon,
    to: "/customer/services",
  },
  // {
  //   title: "Recharge/Bill",
  //   icon: Recharge,
  //   icon2: RechargeIcon,
  //   to: "/customer/recharge",
  // },
 

  // {
  //   title: "BBPS",
  //   icon: bharatbig,
  //   icon2: BbpsIcon,
  //   to: "/customer/bbps",
  // },
  // {
  //   title: "CMS",
  //   icon: Cms2,
  //   icon2: cmsIconWhite,
  //   to: "/customer/cms",
  // },
  // {
  //   title: "Money Transfer",
  //   icon: transfer1,
  //   icon2: MoneyTransferIcon,
  //   to: "/customer/money-transfer",
  // },
  // {
  //   title: "Express Transfer",
  //   icon: ExpressIcon,
  //   icon2: ExpressIcon,
  //   to: "/customer/express-transfer",
  // },
  // {
  //   title: "Super Transfer",
  //   icon: SuperIcon,
  //   icon2: SuperIcon,
  //   to: "/customer/super-transfer",
  // },

  // {
  //   title: "Nepal Transfer",
  //   icon: NepalIcon,
  //   icon2: NepalIcon,
  //   to: "/customer/nepal-transfer",
  // },
  // {
  //   title: "UPI Transfer",
  //   icon: Upi,
  //   icon2: UpiIcon,
  //   to: "/customer/upi-transfer",
  // },
  // {
  //   title: "AEPS",
  //   icon: AEPS1,
  //   icon2: AepsIcon,
  //   to: "/customer/aeps",
  // },
  // {
  //   title: "Travel",
  //   icon: Travel,
  //   icon2: FlightIcon,
  //   to: "/customer/travel",
  //   // to: "/customer/travel-services",
  // },
  {
    title: "Account Ledger",
    icon: ledgerIcon,
    icon2: ledgerIcon,
    to: "/customer/account-ledger",
  },
  {
    title: "Fund Request",
    icon: creditReqBadgeIcon,
    icon2: CreditRequestIcon,
    to: "/customer/cred-req",
  },
  {
    title: "Transactions",
    icon: transactionsvg,
    icon2: ReportsIcon,
    to: "/customer/transactions",
  },

  {
    title: "My Complaints",
    icon: complaintsvg,
    icon2: ComplaintsIconWhite,
    to: "/customer/complaints",
  },
  
  {
    title: "My Purchase",
    icon: purchasesvg,
    icon2: TransactionIconWhite,
    to: "/customer/purchase",
  },
  {
    title: "My Sale",
    icon: mysale,
    icon2: TransactionIconWhite,
    to: "/customer/sale",
  },
  // {
  //         title: "Khata Book",
  //         icon: Book,
  //         icon2: AccountIcon,
  //         to: "/customer/khata-book",
  //       },
  {
    title: "IRCTC",
    icon: train,
    icon2: trainIconWhite,
    to: "https://www.irctc.co.in/nget/train-search",
  },
  {
    title: "Invoices",
    icon: invoicesvg,
    icon2: employeesIconWhite,
    to: "/customer/invoice",
  },
  {
    title: "Login History",
    icon: loginhistory,
    // icon2: TransactionIconWhite,
    to: "/customer/loginHistory",
  },
  // {
  //   title: "Reports",
  //   icon: ReportsIcon,
  //   icon2: ReportsIcon,
  //   to: "#",
  //   submenus: [
  //     {
  //       title: "My Transactions",
  //       icon: TransactionIcon,
  //       icon2: TransactionIconWhite,
  //       to: "/customer/transactions",
  //     },
  //     {
  //       title: "Account Ledger",
  //       icon: AccountIcon,
  //       icon2: AccountIconWhite,
  //       to: "/customer/account-ledger",
  //     },
  //     {
  //       title: "My Complaints",
  //       icon: ComplaintsIcon,
  //       icon2: ComplaintsIconWhite,
  //       to: "/customer/complaints",
  //     },
  //     {
  //       title: "Credit Report",
  //       icon: TransactionIcon,
  //       icon2: TransactionIconWhite,
  //       to: "/customer/purchase",
  //     },
  //     {
  //       title: "Khata Book",
  //       icon: AccountIcon,
  //       icon2: AccountIcon,
  //       to: "/customer/khata-book",
  //     },
  //   ],
  // },
];
export const customer_nav2 = [
    {
    title: "Dashboard",
    icon: dashbaordsvg,
    icon2: DashboardIconWhite,
    to: "/customer/dashboard",
  },
  {
    title: "Money Transfer",
    icon: dashbaordsvg,
    icon2: DashboardIconWhite,
    to: "/customer/money_transfer",
  },
  {
    title: "Wallet Transfer",
    icon: dashbaordsvg,
    icon2: DashboardIconWhite,
    to: "/customer/wallet_transfer",
  },
  {
    title: "Payments",
    icon: Arrow,
    icon2: DashboardIconWhite,
    to: "/customer/payment_transfer",
  },
  {
    title: "Upi Transfer",
    icon: upi,
    icon2: DashboardIconWhite,
    to: "/customer/upi-transfer",
  },
  {
    title: "Nepal Transfer",
    icon: NepalIcon,
    icon2: nepal2,
    to: "/customer/nepal-transfer",
  },
  {
    title: "Aeps",
    icon: AepsIcon,
    icon2: DashboardIconWhite,
    to: "/customer/aeps",
  },
  
  {
    title: "Irctc",
    icon: trainIconWhite,
    icon2: trainIconWhite,
    to: "https://www.irctc.co.in/nget/train-search",
  },
  {
      title: "Recharge/Bill",
      icon: Recharge,
      icon2: RechargeIcon,
      to: "/customer/recharge_new",
    },
    {
      title: "BBPS",
      icon: bharatbig,
      icon2: BbpsIcon,
      to: "/customer/bbps",
    },
    {
      title: "CMS",
      icon: Cms2,
      icon2: cmsIconWhite,
      to: "/customer/cms_new",
    },
    {
    title: "Account Ledger",
    icon: ledgerIcon,
    icon2: ledgerIcon,
    to: "/customer/account-ledger",
  },
  {
    title: "Fund Request",
    icon: creditReqBadgeIcon,
    icon2: CreditRequestIcon,
    to: "/customer/cred-req",
  },
  {
    title: "Transactions",
    icon: transactionsvg,
    icon2: ReportsIcon,
    to: "/customer/transactions",
  },

  {
    title: "My Complaints",
    icon: complaintsvg,
    icon2: ComplaintsIconWhite,
    to: "/customer/complaints",
  },

  {
    title: "My Purchase",
    icon: purchasesvg,
    icon2: TransactionIconWhite,
    to: "/customer/purchase",
  },
  {
    title: "My Sale",
    icon: mysale,
    icon2: TransactionIconWhite,
    to: "/customer/sale",
  },
  {
    title: "IRCTC",
    icon: train,
    icon2: trainIconWhite,
    to: "https://www.irctc.co.in/nget/train-search",
  },
  {
    title: "Invoices",
    icon: invoicesvg,
    icon2: employeesIconWhite,
    to: "/customer/invoice",
  },
  {
    title: "Login History",
    icon: loginhistory,
    // icon2: TransactionIconWhite,
    to: "/customer/loginHistory",
  },
]
// ******************************************************************
// #############################################
// these are the navs for the new layout
// #############################################
// ******************************************************************
export const reports = [
  {
    title: "Dashboard",
    icon: dashboard,
    to: "/customer/dashboard",
  },
  {
    title: "My Transactions",
    icon: transactions,
    to: "/customer/transactions",
  },
  {
    title: "Account Ledger",
    icon: account_ledgre,
    to: "/customer/account-ledger",
  },
  {
    title: "Account Ledger",
    icon: account_ledgre,
    to: "/customer/account-ledger",
  },
  {
    title: "Fund Request",
    icon: creditReqBadgeIcon,
    to: "/customer/cred-req",
  },
  {
    title: "My Complaints",
    icon: myComplaints,
    to: "/customer/complaints",
  },
  {
    title: "Credit Report",
    icon: myPurchase,
    to: "/customer/purchase",
  },
  {
    title: "Khata Book",
    icon: khataBook,
    to: "/customer/khata-book",
  },
  {
    title: "Login History",
    icon: loginhistory,
    // icon2: TransactionIconWhite,
    to: "/customer/loginHistory",
  },
];

export const banking = [
  {
    title: "Money Transfer",
    icon: mtIcon,
    to: "/customer/money-transfer",
  },
  {
    title: "Settlements",
    icon: stIcon,
    to: "/customer/settlements",
  },
  {
    title: "Express Transfer",
    icon: etIcon,
    to: "/customer/express-transfer",
  },
  {
    title: "Super Transfer",
    icon: stIcon,
    to: "/customer/super-transfer",
  },
  {
    title: "AEPS",
    icon: aepsIcon,
    to: "/customer/aeps",
  },
  {
    title: "Nepal Transfer",
    icon: ntIcon,
    to: "/customer/nepal-transfer",
  },
  {
    title: "CMS",
    icon: CMSIcon,
    to: "/customer/cms",
  },
  {
    title: "UPI Transfer",
    icon: utIcon,
    to: "/customer/upi-transfer",
  },
  {
    title: "Login History",
    // icon: loginHistory,
    // icon2: TransactionIconWhite,
    to: "/customer/loginHistory",
  },
];

export const bbpsServices = [
  {
    title: "Electricity",
    icon: <FormatBoldIcon />,
    to: "/customer/bbps",
    isModal: true,
  },
  {
    title: "Gas Cylinder",
    icon: <FormatBoldIcon />,
    to: "/customer/bbps",
    isModal: true,
  },
  {
    title: "Mobile Postpaid",
    icon: <FormatBoldIcon />,
    to: "/customer/bbps",
    isModal: true,
  },
  {
    title: "Mobile Prepaid",
    icon: <FormatBoldIcon />,
    to: "/customer/bbps",
    isModal: true,
  },
  {
    title: "FASTag",
    icon: <FormatBoldIcon />,
    to: "/customer/bbps",
    isModal: true,
  },
  {
    title: "Piped Gas",
    icon: <FormatBoldIcon />,
    to: "/customer/bbps",
    isModal: true,
  },
  {
    title: "BBPS",
    icon: <FormatBoldIcon />,
    to: "/customer/bbps",
  },
];
export const ourServices = [
  {
    title: "Mobile Recharge",
    icon: Prepaid,
    to: "",
    isModal: true,
  },
  {
    title: "DTH Recharge",
    icon: dthIcon,
    to: "",
    isModal: true,
  },
  {
    title: "Electricity with commission",
    icon: elecIcon,
    to: "",
    isModal: true,
  },
  {
    title: "LIC Premium",
    icon: licIcon,
    to: "",
    isModal: true,
  },
  {
    title: "Credit Card Bill",
    icon: cardIcon,
    to: "",
    isModal: true,
  },
  {
    title: "Gas Bill",
    icon: gasIcon,
    to: "",
    isModal: true,
  },
  {
    title: "Broadband Bill",
    icon: bbIcon,
    to: "",
    isModal: true,
  },
  {
    title: "Water Bill",
    icon: waterIcon,
    to: "",
    isModal: true,
  },
  {
    title: "Landline Bill",
    icon: landIcon,
    to: "",
    isModal: true,
  },
  // {
  //   title: "Recharges",
  //   icon: Prepaid,
  //   to: "/customer/recharge",
  // },
];

export const travelServices = [
  {
    title: "Flight Booking",
    icon: flightBooking,
    to: "/customer/travel",
  },
  {
    title: "IRCTC Booking",
    icon: irctcTravelImage,
    to: "https://www.irctc.co.in/nget/train-search",
    target: "_blank",
  },
  {
    title: "Hotel Booking",
    icon: hotelBooking,
    to: "/customer/travel",
  },
  {
    title: "Bus Booking",
    icon: busBooking,
    to: "/customer/travel",
  },
];

export const getNavImg = (title) => {
  let image;
  switch (title) {
    case "Electricity":
      image = Electricity;
      break;
    case "FASTag":
      image = FASTag;
      break;
    case "BBPS":
      image = BBPS;
      break;
    case "Gas Cylinder":
      image = Cylinder;
      break;
    case "Mobile Postpaid":
      image = Postpaid;
      break;
    case "Mobile Prepaid":
      image = Prepaid;
      break;
    case "Piped Gas":
      image = Gas;
      break;

    default:
      image = BBPS;
  }
  return image;
};

// export const customer_nav_super = [
//   {
//     title: "Dashboard",
//     icon: <HomeOutlinedIcon />,
//     to: "/customer/dashboard",
//   },
//   {
//     title: "Recharge/Bill",
//     icon: <RechargeIconutlinedIcon />,
//     to: "/customer/RechargeIcon",
//   },
//   {
//     title: "BBPS",
//     icon: <FormatBoldIcon />,
//     to: "/customer/bbps",
//   },
//   {
//     title: "Money Transfer",
//     icon: <SwapHorizIcon />,
//     to: "/customer/money-transfer",
//   },
//   {
//     title: "Express Transfer",
//     icon: <SwipeRightOutlinedIcon />,
//     to: "/customer/express-transfer",
//   },
//   {
//     title: "Super Transfer",
//     icon: <BoltIcon />,
//     to: "/customer/super-transfer",
//   },
//   {
//     title: "UPI Transfer",
//     // icon: <SyncAltOutlinedIcon />,
//     icon: <QrCodeScannerIcon />,
//     to: "/customer/upi-transfer",
//   },
//   {
//     title: "AEPS",
//     icon: <TouchAppOutlinedIcon />,
//     to: "/customer/aeps",
//   },
//   {
//     title: "My Transactions",
//     icon: <ShuffleIcon />,
//     to: "/customer/transactions",
//   },
//   {
//     title: "Fund Request",
//     icon: <CurrencyRupeeOutlinedIcon />,
//     to: "/customer/cred-req",
//   },
//   {
//     title: "My Complaints",
//     icon: <SupportAgentIcon />,
//     to: "/customer/complaints",
//   },
//   {
//     title: "Credit Report",
//     icon: <FontAwesomeIcon icon={faLink} style={{ fontSize: "18px" }} />,
//     to: "/customer/purchase",
//   },
//   {
//     title: "Khata Book",
//     icon: <MenuBookOutlinedIcon />,
//     to: "/customer/khata-book",
//   },
// ];
export const account_nav = [
  // {
  //   title: "Dashboard",
  //   icon: DashboardIcon,
  //   icon2: DashboardIconWhite,
  //   to: "/account/dashboard",
  // },
  {
    title: "Users",
    icon: usersvg,
    icon2: UserIconWhite,
    to: "/account/users",
  },

   {
    title: "Banking",
    icon: bankingsvg,
    icon2: BankIconWhite,
    to: "/account/banking",
  },
{
    title: "Transaction Data",
      icon: transactionsvg,
      icon2: ReportsIcon,
    to: "/account/transactions",
  },
  {
    title: "Reports",
      icon: dashbaordsvg,
      icon2: ReportsIcon,
    to: "/account/reports",
  },
  // {
  //   title: "Download",
  //     icon: transactionsvg,
  //     icon2: ReportsIcon,
  //   to: "/account/transactions",
  // },

  {
    title: "Login History",
    icon: loginhistory,
    // icon2: TransactionIconWhite,
    to: "/account/loginHistory",
  },
];

//acc login
//user
//bank
//account
//txn
